<template>
	<div class="vmshipsversionFormHeader puiformheader">
		<v-row>
			<v-col cols="12">
				<pui-field :label="$t('header.mshipsversion.imo')" :value="getImoValue"></pui-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<pui-field :label="$t('header.mshipsversion.shipname')" :value="getShipnameValue"></pui-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<pui-field :label="$t('header.mshipsversion.flagdesc')" :value="getFlagdescValue"></pui-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<pui-field :label="$t('header.mshipsversion.flag')" :value="getFlagValue"></pui-field>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'mshipsversion-form-header',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'mshipsversion'
		};
	},
	computed: {
		getImoValue() {
			return this.model && this.model.imo ? this.model.imo : '-';
		},
		getShipnameValue() {
			return this.model && this.model.shipname ? this.model.shipname : '-';
		},
		getFlagdescValue() {
			return this.model && this.model.flagdesc ? this.model.flagdesc : '-';
		},
		getFlagValue() {
			return this.model && this.model.flag ? this.model.flag : '-';
		}
	}
};
</script>
